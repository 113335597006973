import './layout.global.css'

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Topbar from '../Topbar'
import Footer from '../footer'

require('../../scss/style.scss')

require('focus-visible')

const Layout = ({ children, startingClass = 'color-switch--black' }) => (
  <StaticQuery
    query={graphql`
      query LayoutQueries {
        site {
          siteMetadata {
            title
          }
        }
        allPrismicOffices {
          edges {
            node {
              lang
              data {
                office {
                  name
                  street
                  city
                  province_state
                  postal_code
                  country
                  email
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div id="vrst-main" className={startingClass}>
        <Topbar />
        <main>{children}</main>
        <Footer offices={data.allPrismicOffices.edges} />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
