import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import PageHeader from '../components/page-header'
import Drift from '../components/waypoint-drift'

const CodeOfValues = ({ data }) => {
  const page = data.prismicPage?.data
  if (!page) {
    return null
  }
  return (
    <React.Fragment>
      <Helmet
        title="Versett – Code of Values"
        meta={[
          {
            name: 'description',
            content:
              'Eventually, every company needs advice to design, build and scale their business. Here is our why behind the how.',
          },
          { name: 'keywords', content: 'versett, CoV, Code, Values' },
          { property: 'og:title', content: 'Versett – Code of Values' },
          {
            property: 'og:description',
            content:
              'Eventually, every company needs advice to design, build and scale their business. Here is our why behind the how.',
          },
          {
            property: 'og:image',
            content: 'https://versett.com/images/social-thesis.jpg',
          },
          {
            property: 'og:url',
            content: 'https://versett.com/code-of-values/',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { property: 'og:site_name', content: 'Versett' },
          { name: 'twitter:image:alt', content: 'Code of Values' },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Layout>
        <div class="s-basic-page">
          <section class="s-container optimal-width optimal-width--center">
            <PageHeader lede={page.heading} subheading={page.subheading} />
          </section>
          <Drift />
          <section className="s-container optimal-width optimal-width--center">
            <div dangerouslySetInnerHTML={{ __html: page.body.html }} />
          </section>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default CodeOfValues

export const query = graphql`
  query covQueries($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicPage(uid: { eq: "code-of-values" }) {
      data {
        heading
        subheading
        body {
          html
        }
      }
    }
  }
`
