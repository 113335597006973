import React from 'react'
import { Waypoint } from 'react-waypoint'

export default () => {
  return (
    <Waypoint
      topOffset="45%"
      bottomOffset="45%"
      onPositionChange={({ previousPosition, currentPosition }) => {
        currentPosition === 'below' && document.body.classList.add('drift-off')
        currentPosition === 'above' &&
          document.body.classList.remove('drift-off')
      }}
    />
  )
}
